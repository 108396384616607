<template>
    <div class="repairer-rfqs-page">
        <div class="page-header">
          <div class="d-flex justify-content-between">
            <div class="">
              <h4>Request for Quotes</h4>
              <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'RepairerDashboard'}">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">View Quotes</li>
              </ol>
            </div>
            <div class="float-right search-filter-in-header">
              <search-filter
                  type="estimates"
                  v-model="searchFilter2"
                  :limitLength="50"
                  :filterValue="searchFilter2"
                  @selectAdditional="redirectToRfqView"
                  @onEnter="onEnter"
                  :placeholder="'Search'"
                  :additionalMenu="additionalMenu"
                  :isSearch="true"
                  :debug="false"
                  height="41px"
                  aria-expanded="false"
                  data-toggle="dropdown"
              />
            </div>
          </div>
            <div class="page-header-nav-btn">
                <a @click="clickSubmitQuote" class="btn btn-primary btn-submit-quote" v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Start your Request for a Parts quote', placement:'left'}">
                    <i class='bx bx-plus'></i><span>Submit Quote</span>
                </a>
            </div>
        </div>
        <div class="repairer-rfqs-body">
            <tabs ref="repairer-rfqs-table" @changed="tabChanged" cache-lifetime="0" :options="{useUrlFragment:true}" :custom-counts="computedTotalCounts">
                <tab name="Received" id="received" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedReceivedRFQs"
                                :fields="computedReceivedFields"
                                :filter="filter"
                                :current-page="getterReceivedRFQsCurrentPage"
                                :per-page="getterPerPage"
                                :filter-function="filterTable"
                                @row-clicked="clickRFQRow"
                                @row-contextmenu="openMenuForRFQs"
                                @filtered="value => rfqReceivedFiltered = value"
                        >
                            <template v-slot:cell(images)="data">
                              <img v-if="getImageUrl(data)" style="width: 60px; height: 60px; object-fit: cover;" :src="getImageUrl(data)" alt="">
                            </template>
                            <template v-slot:cell(assignedTo)="data">
                              <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
                            </template>
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template #cell(dateReceived)="data">
                                {{ data.value | formatDate }}
                            </template>
                            <template #cell(dateDue)="data">
                                <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br v-if="data.value"/></span>
                                <span v-if="data.value.includes('1970-01-01')"></span>
                                <span v-else>
                                  {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
                                </span>
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                    :isBiddersBlinking="true"
                                        :items="data">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'RFQs'"
                        :arrayOfSomeone="gettersReceivedRFQs"
                        :arrayOfSomeoneFiltered="filter? rfqReceivedFiltered : computedReceivedRFQs"
                        :currentPage="getterReceivedRFQsCurrentPage"
                        @changedCurrentPage="value => $store.commit('repairer/rfq/repairerRFQReceivedCurrentPage', value)"
                        :perPage="getterPerPage"
                        @changedPerPage="value => $store.commit('repairer/rfq/repairerPerPage', value)"
                    >
                    </block-pagination>

                </tab>
                <tab name="Submitted" id="submitted" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedSubmittedRFQs"
                                :fields="computedSubmittedFields"
                                :filter="filter"
                                :filter-function="filterTable"
                                :current-page="getterSubmittedRFQsCurrentPage"
                                :per-page="getterPerPage"
                                @row-clicked="clickRFQRow"
                                @row-contextmenu="openMenuForRFQs"
                                @filtered="value => rfqSubmittedFiltered = value"
                        >
                          <template #cell(bidders)="data">
                            <div class="">

                              <span  v-for="(bidder, ind) in data.value" :key="ind">
                                  <i v-if="!(((bidder.status === 'Declined') || (bidder.status === 'Priced' && bidder.isUpdatedPrices)) && !isShowBidders)"
                                     style="font-size: 23px"
                                     :class="{
                                     'bx bx-minus-circle bidder-color-gray': bidder.status === 'Nil Stock',
                                     'bx bxs-badge-dollar': bidder.status !== 'Nil Stock',
                                     'bidder-color-gray': bidder.status === 'Not Priced',
                                     'bidder-color-red red-icon-blinker': bidder.status === 'Declined',
                                     'bidder-color-green': bidder.status === 'Priced',
                                   }"
                                     v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getBidderTooltip(bidder, data), placement:'leftbottom'}"
                                  ></i>
                                  <i
                                      v-else
                                      class='bx bxs-badge-dollar'
                                      style="font-size: 23px; color: white"
                                  ></i>
                                </span>
                            </div>
                          </template>
                            <template v-slot:cell(images)="data">
                              <img v-if="getImageUrl(data)" style="width: 60px; height: 60px; object-fit: cover;" :src="getImageUrl(data)" alt="">
                            </template>
                            <template v-slot:cell(assignedTo)="data">
                              <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
                            </template>
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template #cell(dateSubmitted)="data">
                                {{ data.value | formatDate }}
                            </template>
                            <template #cell(dateDue)="data">
                              <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br v-if="data.value"/></span>
                                {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                    :isBlinkingOrangeIcon="isBlinkingOrangeIcon(data.item)"
                                    :isBiddersBlinking="isShowBidders"
                                    :items="data">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'RFQs'"
                        :arrayOfSomeone="gettersSubmittedRFQs"
                        :arrayOfSomeoneFiltered="filter? rfqSubmittedFiltered : computedSubmittedRFQs"
                        :currentPage="getterSubmittedRFQsCurrentPage"
                        @changedCurrentPage="changedCurrentPageSubmitted"
                        :perPage="getterPerPage"
                        @changedPerPage="value => $store.commit('repairer/rfq/repairerPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <tab name="Priced" id="priced" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedPricedRFQs"
                                :fields="computedPricedFields"
                                :filter="filter"
                                :filter-function="filterTable"
                                :current-page="getterPricedRFQsCurrentPage"
                                :per-page="getterPerPage"
                                @row-clicked="clickRFQRow"
                                @row-contextmenu="openMenuForRFQs"
                                @filtered="value => rfqPricedFiltered = value"
                        >
                            <template #cell(bidders)="data">
                              <div class="">
                                <span  v-for="(bidder, ind) in data.value" :key="ind">
                                  <i v-if="!(((bidder.status === 'Declined') || (bidder.status === 'Priced' && bidder.isUpdatedPrices)) && !isShowBidders)"
                                     style="font-size: 23px"
                                     :class="{
                                      'bx bx-minus-circle bidder-color-gray': bidder.status === 'Nil Stock',
                                     'bx bxs-badge-dollar': bidder.status !== 'Nil Stock',
                                     'bidder-color-gray': bidder.status === 'Not Priced',
                                     'bidder-color-red red-icon-blinker': bidder.status === 'Declined',
                                     'orange-icon orange-icon-blinker': bidder.status === 'Priced' && bidder.isSentUpdateRequested,
                                     'bidder-color-green': bidder.status === 'Priced',
                                     'green-icon-blinker': bidder.status === 'Priced' && bidder.isUpdatedPrices,
                                   }"
                                     v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: getBidderTooltip(bidder, data), placement:'leftbottom'}"
                                  ></i>
                                  <i
                                      v-else
                                      class='bx bxs-badge-dollar'
                                      style="font-size: 23px; color: white"
                                  ></i>
                                </span>
                              </div>
                            </template>
                            <template v-slot:cell(images)="data">
                              <img v-if="getImageUrl(data)" style="width: 60px; height: 60px; object-fit: cover;" :src="getImageUrl(data)" alt="">
                            </template>
                            <template v-slot:cell(assignedTo)="data">
                              <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
                            </template>
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template #cell(dateSubmitted)="data">
                                {{ data.value | formatDate }}
                            </template>
                            <template #cell(dateDue)="data">
                              <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br v-if="data.value || isSentRequest(data.item)"/></span>
                              <span v-if="isSentRequest(data.item)"  class="orange-icon" :class="{'orange-icon-blinker': isShowBidders}">UPDATE RFQ <br v-if="data.value"/></span>
                                {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                        :isBiddersBlinking="isShowBidders"
                                        :items="data">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'RFQs'"
                        :arrayOfSomeone="gettersPricedRFQs"
                        :arrayOfSomeoneFiltered="filter? rfqPricedFiltered : computedPricedRFQs"
                        :currentPage="getterPricedRFQsCurrentPage"
                        @changedCurrentPage="changedCurrentPagePriced"
                        :perPage="getterPerPage"
                        @changedPerPage="value => $store.commit('repairer/rfq/repairerPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <tab name="Ordered" id="ordered" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedOrderedRFQs"
                                :fields="computedOrderedFields"
                                :filter="filter"
                                :current-page="getterOrderedRFQsCurrentPage"
                                :per-page="getterPerPage"
                                :filter-function="filterTable"
                                @row-clicked="clickRFQRow"
                                @row-contextmenu="openMenuForRFQs"
                                @filtered="value => rfqOrderedFiltered = value"
                        >
                            <template v-slot:cell(images)="data">
                              <img v-if="getImageUrl(data)" style="width: 60px; height: 60px; object-fit: cover;" :src="getImageUrl(data)" alt="">
                            </template>
                            <template v-slot:cell(assignedTo)="data">
                              <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
                            </template>
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template #cell(dateSubmitted)="data">
                                {{ data.value | formatDate }}
                            </template>
                            <template #cell(dateDue)="data">
                              <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br /></span>
                              <span v-if="data.value">{{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}</span>
                              <span v-else>Next available run</span>
                            </template>
                            <template v-slot:cell(orderNumbers)="data">
                                <div v-bind:key="'ordNbrs'+data.item.id+index" v-for="(ord,index) in data.item.orderNumbers">{{ord}}</div>
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                    :isBiddersBlinking="true"
                                        :items="data">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'RFQs'"
                        :arrayOfSomeone="gettersOrderedRFQs"
                        :arrayOfSomeoneFiltered="filter? rfqOrderedFiltered : computedOrderedRFQs"
                        :currentPage="getterOrderedRFQsCurrentPage"
                        @changedCurrentPage="value => $store.commit('repairer/rfq/repairerRFQOrderedCurrentPage', value)"
                        :perPage="getterPerPage"
                        @changedPerPage="value => $store.commit('repairer/rfq/repairerPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <tab name="Cancelled" id="cancelled" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedCancelledRFQs"
                                :fields="cancelledFields"
                                :filter="filter"
                                :current-page="getterCancelledRFQsCurrentPage"
                                :per-page="getterPerPage"
                                :filter-function="filterTable"
                                @row-contextmenu="openMenuForRFQs"
                                @filtered="value => rfqCancelledFiltered = value"
                                @row-clicked="clickRFQRow"
                        >
                            <template v-slot:cell(assignedTo)="data">
                              <span v-if="data.value && data.value.id > 0">{{ data.value.name }}</span>
                            </template>
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template #cell(dateReceived)="data">
                                {{ data.value | formatDate }}
                            </template>
                            <template #cell(dateDue)="data">
                              <span v-if="data && data.item && data.item.isSentAsDirectOrder">DIRECT ORDER<br v-if="data.value"/></span>
                                {{ data.value | formatDate('DD/MM/YYYY || hh:mmA') }}
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                    :isBiddersBlinking="true"
                                        :items="data">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'RFQs'"
                        :arrayOfSomeone="gettersCancelledRFQs"
                        :arrayOfSomeoneFiltered="filter? rfqCancelledFiltered : computedCancelledRFQs"
                        :currentPage="getterCancelledRFQsCurrentPage"
                        @changedCurrentPage="value => $store.commit('repairer/rfq/repairerRFQCancelledCurrentPage', value)"
                        :perPage="getterPerPage"
                        @changedPerPage="value => $store.commit('repairer/rfq/repairerPerPage', value)"
                    >
                    </block-pagination>
                </tab>
                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search">
                      <search-filter
                          type="estimates"
                          v-model="searchFilter"
                          :filters="computedSearchFilters"
                          :limitLength="50"
                          :filterValue="filter"
                          @change="onSearchFilterChange"
                          @input="onSearchFilterInput"
                          :placeholder="'Filter By'"
                          :isShowSearIcon="false"
                          @updateToken="value => $store.commit('repairer/rfq/setToken', value)"
                          :tokenCopy="computedToken"
                          :debug="false"
                          height="41px"
                          aria-expanded="false"
                          data-toggle="dropdown"
                      />
<!--                        <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results"-->
<!--                                      debounce="500"></b-form-input>-->
                    </div>
                    <div class="tabs-nav-right-btn ml-4">
                        <a @click="clickSubmitQuote" class="btn btn-primary btn-submit-quote" v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Start your Request for a Parts quote', placement:'leftbottom'}">
                            <i class='bx bx-plus'></i><span>Submit Quote</span>
                        </a>
                    </div>
                </template>
            </tabs>
        </div>

      <vue-context ref="menuRFQs"
                   style="width: 140px"
                   :lazy="true"
                   @close="contextClose"
                   @open="contextOpen"
                   :closeOnScroll="true">
        <template slot-scope="item">
          <b-button-toolbar key-nav>
            <b-button-group vertical style="width: 140px" class="parts-order-view__context-menu">
              <template  v-if="item && item.data && item.data.row">
                <b-button
                    class="text-left font-12 font-inter--semibold"
                    variant="outline-primary"
                    block
                    @click="viewRFQ(item.data.row)"
                > View RFQ
                </b-button>
                <b-button
                    class="text-left font-12 font-inter--semibold"
                    variant="outline-primary"
                    block
                    @click="openInNewTab(item.data.row.id)"
                >Open in New Tab
                </b-button>

                <div
                    @mouseover="isOpenSecondContextLevel = true"
                    @mouseleave="isOpenSecondContextLevel = false"
                    style="width:100%"
                >
                  <b-button
                      class="text-left font-12 font-inter--semibold d-flex align-items-center justify-content-between pr-0"
                      variant="outline-primary" block
                  >
                    Assign To
                    <i style="font-size: 20px;" class="bx bx-chevron-right text-right"></i>
                  </b-button>
                  <div v-if="isOpenSecondContextLevel" class="users-context-level">
                    <b-button v-for="user in computedUsersForAssignedFiltered(item.data.row)"
                              @click="clickOnAssignedTo(item.data.row, user)"
                              :key="'assigned-to-'+user.id"
                              class="text-left font-12 font-inter--semibold"
                              variant="outline-primary" block>{{ user.name }}
                    </b-button>
                  </div>
                </div>

                <template v-if="currentTab === 'Cancelled'">
                  <b-button
                      class="text-left font-12 font-inter--semibold"
                      variant="outline-primary"
                      block
                      @click="deleteQuote(item.data.row.id)"
                  >Delete Quote
                  </b-button>
                </template>

                <template v-if="currentTab === 'Received' || currentTab === 'Submitted' || currentTab === 'Priced'">
                  <b-button
                      class="text-left font-12 font-inter--semibold"
                      variant="outline-primary"
                      block
                      @click="cancelQuote(item.data.row.id)"
                  >Cancel Quote
                  </b-button>
                </template>

              </template>
            </b-button-group>
          </b-button-toolbar>
        </template>
      </vue-context>

    </div>
</template>
<script>
    /*eslint-disable */
    import {mapGetters, mapActions} from 'vuex';
    import notificationOnTable from "@/components/notification-on-table.vue";
    import moment from "moment";
    import dayjs from "dayjs";
    import Vue from "vue";
    import BlockPagination from '../../../components/utility/block-pagination';
    import SearchFilter from '../../../components/utility/search-filter/search-filter';
    import { VueContext } from 'vue-context'
    import Axios from "axios";
    import _ from "lodash";

    export default {
        name: 'rfqs',
        data: function () {
            return {
                currentTab: '',
                contextOpenEvent: null,
                isOpenSecondContextLevel: false,
                isShowBidders: false,
                // filter: '',
                searchFilter: '',
                searchFilter2: '',
                perPage: 20,
                currentPage: 1,
                rfqReceivedFiltered: [],
                rfqSubmittedFiltered: [],
                rfqPricedFiltered: [],
                rfqOrderedFiltered: [],
                rfqCancelledFiltered: [],
                receivedFields: [
                  {label: "Request Nbr", key: "number", sortable: true, tdClass: 'clickable', thClass: ''},
                  {label: "Quoting Package Nbr", key: "QPNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable ', thClass: ''},
                  {label: "Make", key: "make", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Model", key: "model", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable ', thClass: 'notification-column'},
                  {label: "Date Received", key: "dateReceived", sortable: true, tdClass: 'clickable ', thClass: ''},
                  {label: "Due Date : Time", key: "dateDue", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Assigned To", key: "assignedTo", sortable: true, tdClass: 'clickable number', thClass: ''},
                ],
                submittedFields: [
                  {label: "Request Nbr", key: "number", sortable: true, tdClass: 'clickable ', thClass: ''},
                  {label: "Quoting Package Nbr", key: "QPNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable', thClass: ''},
                  {label: "Make", key: "make", sortable: true, tdClass: 'clickable  number', thClass: ''},
                  {label: "Model", key: "model", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable', thClass: 'notification-column'},
                  {label: "Date Submitted", key: "dateSubmitted", sortable: true, tdClass: 'clickable', thClass: ''},
                  {label: "Due Date : Time", key: "dateDue", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: 'Bidders', key: 'bidders', sortable: true, tdClass: 'clickable number bidders-item', thClass: 'bidders-item'},
                  {label: "Assigned To", key: "assignedTo", sortable: true, tdClass: 'clickable  number', thClass: ''},
                  {label: "Quote Completion", key: "quoteCompletion", sortable: true, tdClass: 'clickable', thClass: ''},
                ],
                pricedFields: [
                  {label: "Request Nbr", key: "number", sortable: true, tdClass: 'clickable ', thClass: ''},
                  {label: "Quoting Package Nbr", key: "QPNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable', thClass: ''},
                  {label: "Make", key: "make", sortable: true, tdClass: 'clickable  number', thClass: ''},
                  {label: "Model", key: "model", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable', thClass: 'notification-column'},
                  {label: "Date Submitted", key: "dateSubmitted", sortable: true, tdClass: 'clickable', thClass: ''},
                  {label: "Due Date : Time", key: "dateDue", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: 'Bidders', key: 'bidders', sortable: true, tdClass: 'clickable number bidders-item', thClass: 'bidders-item'},
                  {label: "Assigned To", key: "assignedTo", sortable: true, tdClass: 'clickable  number', thClass: ''},
                  {label: "Quote Completion", key: "quoteCompletion", sortable: true, tdClass: 'clickable', thClass: ''},
                ],
                orderedFields: [
                  {label: "Request Nbr", key: "number", sortable: true, tdClass: 'clickable ', thClass: ''},
                  {label: "Quoting Package Nbr", key: "QPNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Order Nbr", key: "orderNumbers", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable', thClass: ''},
                  {label: "Make", key: "make", sortable: true, tdClass: 'clickable  number', thClass: ''},
                  {label: "Model", key: "model", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable', thClass: 'notification-column'},
                  {label: "Date Submitted", key: "dateSubmitted", sortable: true, tdClass: 'clickable', thClass: ''},
                  {label: "Due Date : Time", key: "dateDue", sortable: true, tdClass: 'clickable number', thClass: ''},
                  {label: "Assigned To", key: "assignedTo", sortable: true, tdClass: 'clickable  number', thClass: ''},
                  {label: "Cost", key: "costStr", sortable: true, tdClass: 'clickable order-cost text-right number', thClass: ''},
                ],
                cancelledFields: [
                    {label: "Request Nbr", key: "number", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Quoting Package Nbr", key: "QPNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Make", key: "make", sortable: true, tdClass: 'clickable  number', thClass: ''},
                    {label: "Model", key: "model", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable ', thClass: 'notification-column'},
                    {label: "Date Received", key: "dateReceived", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Due Date : Time", key: "dateDue", sortable: true, tdClass: 'clickable  number', thClass: ''},
                    {label: "Assigned To", key: "assignedTo", sortable: true, tdClass: 'clickable  number', thClass: ''},
                ],
            };
        },
        methods: {
            ...mapActions({
                setFilter: 'repairer/rfq/setFilter'
            }),
            redirectToRfqView(itm) {
              if (itm.isJobView) {
                this.setFilter('')
                this.$router.push({name: 'RepairerJobView', params: {job_id: itm.value}});
              } else {
                this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: itm.value } })
              }
            },
            onEnter() {
              setTimeout(() => {
                let rfq =  _.find(this.additionalMenu, (r) => {
                  return r.isJobView
                })

                if (rfq && !_.isEmpty(this.searchFilter2)) {
                  this.setFilter('')
                  this.$router.push({name: 'RepairerJobView', params: {job_id: rfq.value}});
                  // this.$router.push({ name: 'RepairerRFQView', params: { rfq_id: rfq.id } })
                }
              }, 500)

            },
            isSentRequest(item) {
              let bidders = _.filter(item?.bidders, (b) => {
                return b.status === 'Priced' && b.isSentUpdateRequested
              })
              return !_.isEmpty(bidders)
            },
            changedCurrentPagePriced(value) {
              this.$store.commit('repairer/rfq/repairerRFQPricedCurrentPage', value);
              this.isShowBidders = false
              setTimeout(() => {
                this.isShowBidders = true
              }, 200)
            },
            changedCurrentPageSubmitted(value) {
              this.$store.commit('repairer/rfq/repairerRFQSubmittedCurrentPage', value);
              this.isShowBidders = false
              setTimeout(() => {
                this.isShowBidders = true
              }, 200)
            },
            getBidderTooltip(bidder, data) {
              let r
              if (bidder.status === 'Priced' && bidder.isSentUpdateRequested) {
                r = bidder.supplier + ' ' + '<br /><b>' + 'to be updated' + '</b>'
              } else if (bidder.status == 'Declined' && data.item.isSentAsDirectOrder) {
                r = bidder.supplier + ' ' + '<br /><b>' + 'Declined Direct Order' + '</b>'
              } else {
                r = bidder.supplier + ' ' + '<br /><b>' + bidder.status + '' + '</b>'
              }

              if (bidder.declineReason) {
                r += `<br/> ${bidder.declineReason}`
              }
              return r
            },
            getImageUrl(data) {
              if (data?.item?.pinImage) {
                return data.item.pinImage+'?at='+this.getterAuthToken
              }
              return false
            },
            clickOnAssignedTo (row, u) {
              let user = _.cloneDeep(u);
              if (Number(user.id) === Number(this.currentUser.id)) {
                user.name = this.currentUser.fullName;
              }
              Axios.post('ir/repairer/rfq/' + row.id + '/assign-user', { userId: user.id })
                  .then(response => {
                    if (response.data._status) {
                      this.$store.commit('repairer/rfq/setAssignedUser', { id: row.id, user: user })
                    }
                  })
              this.$refs.menuRFQs.close()
              console.log('clickOnAssignedTo', row, user)
            },
            isBlinkingOrangeIcon(item) {
              let settingsValue = 2

              let time = Number(settingsValue) ? Number(settingsValue) : 10
              let diff = dayjs(item.dateDue).diff(dayjs())
              if (diff <= time * 60 * 1000 && diff >= 0) {
                return true
              }
              return false
            },
            cancelQuote: function (id) {
              Axios.post('/ir/repairer/rfq/' + id + '/cancel', {
                cancelId: id
              }).then(response => {
                this.$toast.success('Quote has been cancelled')
                this.$store.commit('repairer/rfq/repairerRFQUpdateStatus', {id: id, status: 'Cancelled'})
                this.$store.dispatch('repairer/rfq/init', {}, { root: true })
              }).catch(error => {
                this.$toast.error('Quote haven\'t been cancelled')
                console.log(error)
              })
            },
            deleteQuote (id) {
              if (!confirm('Are you sure?')) {
                return
              }
              Axios.post('/ir/repairer/rfq/' + id + '/delete', {
                rfqId: id
              }).then(response => {
                this.$toast.success('Quote has been deleted')
                this.$store.commit('repairer/rfq/repairerRFQDelete', { id: id })
                this.$store.dispatch('repairer/rfq/init', {}, { root: true })
              }).catch(error => {
                this.$toast.error('Quote haven\'t been deleted')
                console.log(error)
              })
              this.$refs.menuRFQs.close()
            },
          viewRFQ (row) {
            this.$router.push({name: 'RepairerRFQView', params: {rfq_id: row.id, isNeedOpenChat: row.hasUnreadMessages}});
            this.$refs.menuRFQs.close();
          },
          openInNewTab (id) {
            window.open(window.location.origin + '/r/rfq/' + id, '_blank')
            this.$refs.menuRFQs.close()
          },
          contextOpen: function (event) {
            this.contextOpenEvent = event
          },
          contextClose: function () {
            this.isOpenSecondContextLevel = false
            let event = this.contextOpenEvent
            if (event) {
              let row = event.target.parentElement
              let rows = row.parentElement.rows
              while (!rows) {
                row = row.parentElement
                rows = row.parentElement.rows
              }
              if (rows) {
                for (let i = 0; i < rows.length; i++) {
                  let elem = rows[i]
                  elem.style.backgroundColor = ''
                }
              }
            }
          },
          openMenuForRFQs (row, index, event) {
            event.preventDefault()
            let tableRow = event.target.parentElement
            let rows = tableRow.parentElement.rows
            while (!rows) {
              tableRow = tableRow.parentElement
              rows = tableRow.parentElement.rows
            }
            if (rows) {
              for (let i = 0; i < rows.length; i++) {
                let elem = rows[i]
                elem.style.backgroundColor = ''
              }
            }
            tableRow.style.backgroundColor = 'rgb(233,248,249)'
            this.$refs.menuRFQs.open(event, { row: row, index: index })
          },
          computedUsersForAssignedFiltered (row) {
            if (!row.assignedTo) {
              return this.computedUsers.slice()
            } else {
              return _.filter(this.computedUsersForAssigned, (user) => {
                return Number(user.id) !== Number(row.assignedTo.id)
              })
            }
          },
            onSearchFilterChange(filters) {
              console.log(filters)
              this.$store.commit('repairer/rfq/repairerSetSearchFilterObj', filters);
              if (filters && filters.search) {
                this.setFilter(filters.search);
                // this.filter = filters.search;
              } else {
                this.setFilter('');
                // this.filter = '';
              }
              // console.log('onSearchFilterChange', filters);
              this.isShowBidders = false
              setTimeout(() => {
                this.isShowBidders = true
              }, 200)
            },
            onSearchFilterInput() {
              this.isShowBidders = false
              setTimeout(() => {
                this.isShowBidders = true
              }, 200)
            },
            tabChanged (selectedTab) {
                console.log('Tab changed to:' + selectedTab.tab.name);
                this.currentTab = selectedTab.tab.name;
            },
            filterByPercentRange(percent, percentFrom = 0, percentTo = 25){
                return percent >= percentFrom && percent <= percentTo;
            },
            filterByDatePriced(datePriced = false, hoursFrom = 0, hoursTo = false){
                if(datePriced === false){
                    return false;
                }
                let ct = (Vue.prototype.$http.defaults.headers.common['CompanyTimeOffset'] || 0) * 1;
                let dp = dayjs(datePriced).unix();
                let dn = dayjs().unix();
                dp = Math.floor((dn - dp) / 3600 + ct);
                return dp >= hoursFrom && (!hoursTo || dp <= hoursTo);
            },
            filterByTimeOldRange(datePriced, hoursFrom = 0, hoursTo = 0) {
                //Recently Priced value = number of priced quotes in the last 2 hours
                //1 day old value = number of priced quotes that are from 4 hr to 24 hrs old
                //Over 2 days old value = number of priced quotes that are over 48 hours old.
                let dateFrom = (hoursFrom)?moment().subtract(hoursFrom, 'hour'):moment(0),
                    dateTo = moment().subtract(hoursTo, 'hour');
                return datePriced > dateFrom && row.datePriced < dateTo;
            },
            filterTable(row, filter) {
                let formattedFilter = filter.toLowerCase();
                let percent = parseInt(row.quoteCompletion);
                if (this.currentTab === 'Submitted') {
                    if (filter === 'upto 25%') return this.filterByPercentRange(percent, 0, 25);
                    else if (filter === 'upto 50%') return this.filterByPercentRange(percent, 26, 50);
                    else if (filter === 'upto 75%') return this.filterByPercentRange(percent, 51, 75);
                }
                if (this.currentTab === 'Priced') {
                    if (filter === 'Recently Priced') return this.filterByDatePriced(row.datePriced, 0, 8);
                    else if (filter === '1 day old') return this.filterByDatePriced(row.datePriced, 24, 48);
                    else if (filter === 'Over 2 days old') return this.filterByDatePriced(row.datePriced, 49);
                }

                if (this.isHasFilters) {
                  if (this.isRegoFilterActive && row.rego && _.includes(_.toLower(row.rego), formattedFilter)) {
                    return true;
                  } else if (this.isMakeFilterActive && row.make && _.includes(_.toLower(row.make), formattedFilter)) {
                    return true;
                  } else if (this.isOrderNumberFilterActive && row.orderNumbers && row.orderNumbers.length && _.includes(_.toLower(_.join(row.orderNumbers, ' ')), formattedFilter)) {
                    return true;
                  } else if (this.isQuotingPackageNbrFilterActive && row.QPNumber && _.includes(_.toLower(row.QPNumber), formattedFilter)) {
                    return true;
                  } else if (this.isVINFilterActive && row.vin && _.includes(_.toLower(row.vin), formattedFilter)) {
                    return true;
                  }

                } else {
                  let dateDue = row.dateDue ? moment(row.dateDue).format('DD/MM/YYYY || hh:mmA') : '';
                  let dateReceived = row.dateReceived ? moment(row.dateReceived).format('DD/MM/YYYY') : '';
                  let datePriced = row.datePriced ? moment(row.datePriced).format('DD/MM/YYYY') : '';
                  let dateSubmitted = row.dateSubmitted ? moment(row.dateSubmitted).format('DD/MM/YYYY') : '';

                  if (row.QPNumber && _.includes(_.toLower(row.QPNumber), formattedFilter)) {
                    return true
                  } else if (row.costStr && _.includes(_.toLower(row.costStr), formattedFilter)) {
                    return true
                  } else if (dateDue && _.includes(_.toLower(dateDue), formattedFilter)) {
                    return true
                  } else if (dateReceived && _.includes(_.toLower(dateReceived), formattedFilter)) {
                    return true
                  } else if (datePriced && _.includes(_.toLower(datePriced), formattedFilter)) {
                    return true
                  } else if (dateSubmitted && _.includes(_.toLower(dateSubmitted), formattedFilter)) {
                    return true
                  } else if (row.dom && _.includes(_.toLower(row.dom), formattedFilter)) {
                    return true
                  } else if (row.make && _.includes(_.toLower(row.make), formattedFilter)) {
                    return true
                  } else if (row.model && _.includes(_.toLower(row.model), formattedFilter)) {
                    return true
                  } else if (row.number && _.includes(_.toLower(row.number), formattedFilter)) {
                    return true
                  }  else if (row.orderNumbers && row.orderNumbers.length && _.includes(_.toLower(_.join(row.orderNumbers, ' ')), formattedFilter)) {
                    return true
                  }  else if (row.quoteCompletion && _.includes(_.toLower(row.quoteCompletion), formattedFilter)) {
                    return true
                  }  else if (row.rego && _.includes(_.toLower(row.rego), formattedFilter)) {
                    return true
                  }  else if (row.vin && _.includes(_.toLower(row.vin), formattedFilter)) {
                    return true
                  } else if (row.partsNumbers && _.includes(_.toLower(row.partsNumbers), formattedFilter)) {
                    return true;
                  } else if (row.suppliers && row.suppliers.length &&  _.includes(_.toLower(_.join(row.suppliers, ' ')), formattedFilter)) {
                    return true;
                  }

                }

                // return _.some(row, v => _.includes(v, filter));
            },
            clickRFQRow:function (item,index,event) {
                this.$router.push({name: 'RepairerRFQView', params: {rfq_id: item.id, isNeedOpenChat: item.hasUnreadMessages}});
                return;
            },
            clickSubmitQuote() {
                console.log("111", this.$refs['repairer-rfqs-table']);
                console.log("222", this.$refs['repairer-rfqs-table'].activeTabHash);
                if (this.$refs['repairer-rfqs-table'] && this.$refs['repairer-rfqs-table'].activeTabHash && this.$refs['repairer-rfqs-table'].activeTabHash == "#received") {
                    this.$router.push('/r/rfq/add/received');
                } else {
                    this.$router.push({name: 'RepairerRFQAdd'});
                }
                return;
            },
        },
        watch: {
          gettersPricedRFQs: {
            handler() {
              this.isShowBidders = false
              setTimeout(() => {
                this.isShowBidders = true
              }, 200)
            },
            immediate: true
          },
          gettersSubmittedRFQs: {
            handler() {
              this.isShowBidders = false
              setTimeout(() => {
                this.isShowBidders = true
              }, 200)
            },
            immediate: true
          },
        },
        computed:{
            ...mapGetters({
                gettersReceivedRFQs: 'repairer/rfq/getReceivedRFQs',
                gettersAllRFQs: 'repairer/rfq/getAllRFQs',
                gettersSubmittedRFQs: 'repairer/rfq/getSubmittedRFQs',
                gettersPricedRFQs: 'repairer/rfq/getPricedRFQs',
                gettersOrderedRFQs: 'repairer/rfq/getOrderedRFQs',
                gettersCancelledRFQs: 'repairer/rfq/getCancelledRFQs',
                gettersFilter: 'repairer/rfq/getFilter',
                getterReceivedRFQsCurrentPage: 'repairer/rfq/getReceivedRFQsCurrentPage',
                getterSubmittedRFQsCurrentPage: 'repairer/rfq/getSubmittedRFQsCurrentPage',
                getterPricedRFQsCurrentPage: 'repairer/rfq/getPricedRFQsCurrentPage',
                getterOrderedRFQsCurrentPage: 'repairer/rfq/getOrderedRFQsCurrentPage',
                getterCancelledRFQsCurrentPage: 'repairer/rfq/getCancelledRFQsCurrentPage',
                getterPerPage: 'repairer/rfq/getPerPage',
                getterSearchFilterObj: 'repairer/rfq/getSearchFilterObj',
                getterAllUsers: 'users/getAllUsersForAssignedTo',
                getterToken: 'repairer/rfq/getToken',
                currentUser: 'currentUser/getCurrentUserInfo',
                getterSettings: 'settings/getSettings',
                isEstimator: 'currentUser/isEstimator',
              getterAuthToken: 'auth/getAuthToken',
            }),
          additionalMenu() {
              if (_.isEmpty(this.searchFilter2)) {
                return []
              }
            let data = _.filter(this.gettersAllRFQs, (r) => {
              return _.startsWith(_.trim(_.toLower(r.QPNumber)), _.trim(_.toLower(this.searchFilter2)))
            })
            let result = [];
            _.forEach(data, (itm) => {
              let label = itm.QPNumber
              if (itm.rego) {
                label +=  ' - '
              }

              if (itm.rego) {
                label += itm.rego + ' '
              }

              result.push({
                value: itm.id,
                label: label,
                QPNumber: itm.QPNumber,
                isSupplement: itm.isSupplement,
                jobViewId: itm.jobViewId
              })
            })
            result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
            let addedJobViewIds=[]
            let jobViewOptions = []
            _.forEach(result, (r) => {
              if (!_.includes(addedJobViewIds, r.jobViewId)) {
                let l = 'JOB VIEW'
                if (r.QPNumber) {
                  l = r.QPNumber + ' - JOB VIEW'
                }
                if (r.isSupplement || r.value != r.jobViewId) {
                  if (result.length == 1) {
                    jobViewOptions.push({
                      value: r.value,
                      label: l,
                      isJobView: true
                    })
                    addedJobViewIds.push(r.jobViewId)
                  }
                } else {
                  jobViewOptions.push({
                    value: r.value,
                    label: l,
                    isJobView: true
                  })
                  addedJobViewIds.push(r.jobViewId)
                }
              }
            })
            _.forEach(jobViewOptions, (j) => {
              let index = _.findIndex(result, (r) => {
                return Number(r.jobViewId) === Number(j.value)
              })
              if (index !== -1) {
                result.splice(index, 0, j)
              }
            })
            return result
          },
          isHasPinImg() {
            return this.getterSettings?.rfq?.tableDisplayPinnedImages?.active && _.some(this.gettersAllRFQs, (rfq) => {
              return rfq.pinImage;
            })
          },
          computedReceivedFields () {
            let fields = this.receivedFields.slice();
            if (this.isHasPinImg) {
              fields.unshift(
                  {label: "", key: "images", sortable: false, tdClass: 'clickable number images-block', thClass: ''},
              )
            }
            return fields
          },
          computedSubmittedFields () {
            let fields = this.submittedFields.slice();
            if (this.isHasPinImg) {
              fields.unshift(
                  {label: "", key: "images", sortable: false, tdClass: 'clickable number images-block', thClass: ''},
              )
            }
            return fields
          },
          computedPricedFields () {
            let fields = this.pricedFields.slice();
            if (this.isHasPinImg) {
              fields.unshift(
                  {label: "", key: "images", sortable: false, tdClass: 'clickable number images-block', thClass: ''},
              )
            }
            // if (!this.isEstimator) {
            //   let index = _.findIndex(fields, (f) => {
            //     return f.key === 'bidders'
            //   })
            //   if (index !== -1) {
            //     fields.splice(index, 1)
            //   }
            // }
            return fields
          },
          computedOrderedFields () {
            let fields = this.orderedFields.slice();
            if (this.isHasPinImg) {
              fields.unshift(
                  {label: "", key: "images", sortable: false, tdClass: 'clickable number images-block', thClass: ''},
              )
            }
            return fields
          },
          computedUsers () {
            let data = this.getterAllUsers.slice()
            let currUser = _.find(data, (user) => {
              return Number(user.id) === Number(this.currentUser.id);
            });
            if (currUser) {
              currUser.name = 'Me'
              data = _.filter(data, (itm) => {
                return Number(itm.id) !== Number(currUser.id);
              });
              data.unshift({ name: 'Me', id: this.currentUser.id })
            }
            return data
          },
          computedUsersForAssigned () {
            let data = this.getterAllUsers.slice()
            let currUser = _.find(data, (user) => {
              return Number(user.id) === Number(this.currentUser.id);
            });
            data.unshift({ name: 'None', id: null })
            if (currUser) {
              currUser.name = 'Me'
              data = _.filter(data, (itm) => {
                return Number(itm.id) !== Number(currUser.id);
              });
              data.unshift({ name: 'Me', id: this.currentUser.id })
            }
            // if (data) {
            // }
            return data
          },
          computedReceivedRFQs() {
              let rfqs = this.gettersReceivedRFQs.slice();
              if (this.searchFilterObj.partNumber) {
                let formattedFilter = this.searchFilterObj.partNumber.toLowerCase();
                return _.filter(rfqs, rfq => {
                  return rfq.partsNumbers && _.includes(_.toLower(rfq.partsNumbers), formattedFilter)
                })
              }
              return  rfqs;
          },
          computedSubmittedRFQs() {
            let rfqs = this.gettersSubmittedRFQs.slice();
            if (this.searchFilterObj.partNumber) {
              let formattedFilter = this.searchFilterObj.partNumber.toLowerCase();
              return _.filter(rfqs, rfq => {
                return rfq.partsNumbers && _.includes(_.toLower(rfq.partsNumbers), formattedFilter)
              })
            }
            if (this.searchFilterObj.supplier) {
              let formattedFilter = this.searchFilterObj.supplier;
              return _.filter(rfqs, rfq => {
                return rfq.suppliers && rfq.suppliers.includes(formattedFilter)
              })
            }
            return  rfqs;
          },
          computedPricedRFQs() {
            let rfqs = this.gettersPricedRFQs.slice();
              if (this.searchFilterObj.partNumber) {
                let formattedFilter = this.searchFilterObj.partNumber.toLowerCase();
               rfqs =  _.filter(rfqs, rfq => {
                  return rfq.partsNumbers && _.includes(_.toLower(rfq.partsNumbers), formattedFilter)
                })
              }
            if (this.searchFilterObj.supplier) {
              let formattedFilter = this.searchFilterObj.supplier;
              rfqs =  _.filter(rfqs, rfq => {
                return rfq.suppliers && rfq.suppliers.includes(formattedFilter)
              })
            }

            let rfqRequestedUpdated = _.filter(rfqs, (r) => {
              return this.isSentRequest(r)
            })
            let rfqNotRequestedUpdated = _.filter(rfqs, (r) => {
              return !this.isSentRequest(r)
            })
            let result = [];
            _.forEach(rfqRequestedUpdated, (r) => {
              result.push(r)
            })
            _.forEach(rfqNotRequestedUpdated, (r) => {
              result.push(r)
            })
              return  result;
          },
          computedOrderedRFQs() {
                let rfqs = this.gettersOrderedRFQs.slice();
              if (this.searchFilterObj.partNumber) {
                let formattedFilter = this.searchFilterObj.partNumber.toLowerCase();
                return _.filter(rfqs, rfq => {
                  return rfq.partsNumbers && _.includes(_.toLower(rfq.partsNumbers), formattedFilter)
                })
              }
            if (this.searchFilterObj.supplier) {
              let formattedFilter = this.searchFilterObj.supplier;
              return _.filter(rfqs, rfq => {
                return rfq.suppliers && rfq.suppliers.includes(formattedFilter)
              })
            }
              return  rfqs;
          },
          computedCancelledRFQs() {
                let rfqs = this.gettersCancelledRFQs.slice();
              if (this.searchFilterObj.partNumber) {
                let formattedFilter = this.searchFilterObj.partNumber.toLowerCase();
                return _.filter(rfqs, rfq => {
                  return rfq.partsNumbers && _.includes(_.toLower(rfq.partsNumbers), formattedFilter)
                })
              }
            if (this.searchFilterObj.supplier) {
              let formattedFilter = this.searchFilterObj.supplier;
              return _.filter(rfqs, rfq => {
                return rfq.suppliers && rfq.suppliers.includes(formattedFilter)
              })
            }
              return  rfqs;
          },
          filter: {
            get() {
              return this.gettersFilter;
            },
            set (value) {
              this.setFilter(value);
            }
          },
          computedToken() {
            return this.getterToken;
          },
            isRegoFilterActive() {
              return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('rego'));
            },
            isMakeFilterActive() {
              return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('make'));
            },
            isVINFilterActive() {
              return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('vin'));
            },
            isOrderNumberFilterActive() {
              return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('orderNumber'));
            },
            isPartNumberFilterActive() {
              return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('partNumber'));
            },
          isSupplierFilterActive() {
            return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('supplier'));
          },
            isQuotingPackageNbrFilterActive() {
              return !!(this.searchFilterObj && this.searchFilterObj.hasOwnProperty('quotingPackageNbr'));
            },
            isHasFilters() {
              return this.isRegoFilterActive || this.isMakeFilterActive || this.isVINFilterActive || this.isOrderNumberFilterActive || this.isQuotingPackageNbrFilterActive;
            },
            computedSearchFilters() {
              let partNumbers = {}
              let suppliers = {}

              this.gettersAllRFQs.forEach(rfq => {
                if (rfq.partsNumbers) {
                  let numbers = rfq.partsNumbers.split(';');
                  _.forEach(numbers, n => {
                    partNumbers[n.toLowerCase().trim()] = { label: n.toLowerCase().trim() }
                  })
                }
                if (rfq.suppliers) {
                  _.forEach(rfq.suppliers, s => {
                    suppliers[s.toLowerCase().trim()] = { label: s.trim() }
                  })
                }

              });

              partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ["asc"]);
              suppliers = _.orderBy(Object.values(suppliers), [(itm) => itm.label.toLowerCase().trim()], ["asc"]);

              // Create date template
              const date = [
                {
                  id: 'date',
                  label: "Date",
                  resultObjectOnly: true,
                  type: "Date",
                  iconClass: "bx-calendar"
                }
              ];

              // Create searchFilter filters
              let r = [
                {
                  id: 'partNumber',
                  label: 'Part Number',
                  iconClass: 'bx-user',
                  options: [
                    partNumbers,
                  ]
                },
                {
                  id: 'make',
                  label: 'Make',
                  iconClass: 'bx-user',
                  options: []
                },
                {
                  id: 'rego',
                  label: 'Rego',
                  iconClass: 'bx-user',
                  options: []
                },
                {
                  id: 'vin',
                  label: 'VIN',
                  iconClass: 'bx-user',
                  options: []
                },
                {
                  id: 'orderNumber',
                  label: 'Order Number',
                  iconClass: 'bxs-user-badge',
                  options: []
                },
                {
                  id: 'quotingPackageNbr',
                  label: 'Quoting Package Nbr',
                  iconClass: 'bx-car',
                  options: []
                },
              ];
              if (this.currentTab !== 'Received') {
                r.push({
                  id: 'supplier',
                  label: 'Suppliers',
                  iconClass: 'bx-car',
                  options: [
                    suppliers,
                  ]
                },)
              }
              return r
            },
            searchFilterObj() {
              return this.getterSearchFilterObj;
            },
            computedTotalCounts() {
              let counts = {
                received: 0,
                submitted: 0,
                priced: 0,
                ordered: 0,
                cancelled: 0,
              }
              counts.received = this.gettersReceivedRFQs.length;
              counts.submitted = this.gettersSubmittedRFQs.length;
              counts.priced = this.gettersPricedRFQs.length;
              counts.ordered = this.gettersOrderedRFQs.length;
              counts.cancelled = this.gettersCancelledRFQs.length;
              return counts;
            },
            isReceivedTabActive() {
                this.$nextTick(() => {
                    if (this.$refs['repairer-rfqs-table'] && this.$refs['repairer-rfqs-table'].activeTabHash && this.$refs['repairer-rfqs-table'].activeTabHash == "#received") {
                        return true;
                    }
                });
                return false;
            },
        },
        mounted() {
            if(this.currentTab === 'Submitted' || this.currentTab === 'Priced') {
              let filter = this.$route.query.filter;
              if (filter) {
                this.setFilter(filter);
              }
            }
            console.log('mounted');
            if (this.$route.params.activeTab) {
              this.$refs["repairer-rfqs-table"].selectTab(this.$route.params.activeTab)
            }
        },
        components: {
            notificationOnTable,
            BlockPagination,
            VueContext,
            SearchFilter,
        }
    };
</script>

<style scoped>
  .users-context-level {
    position: absolute;
    border: 1px solid #bdbdbd;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    background: white;
    left: 138px;
    top: 84px;
    width: 170px;
    max-height: 400px;
    overflow-y: auto;
  }
</style>

<style>
.theme-repairer .repairer-rfqs-page .table .notification-column {
  min-width: 130px !important;
}
</style>
