<template>
  <div class="supplier-parts-orders-page">
    <div class="page-header">
      <div class="d-flex justify-content-between">
        <div class="">
          <h4>Parts Orders</h4>
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'SupplierDashboard'}">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active">View Parts Order</li>
          </ol>
        </div>
        <div class="search-filter-in-header d-flex">
          <window-notification></window-notification>
          <search-filter
              type="estimates"
              v-model="searchFilter2"
              :filterValue="searchFilter2"
              @selectAdditional="redirectToRfqView"
              @onEnter="onEnter"
              :additionalMenu="additionalMenu"
              :placeholder="'Search'"
              :isSearch="true"
              height="41px"
              aria-expanded="false"
              data-toggle="dropdown"
          />
        </div>
      </div>
    </div>
    <div class="supplier-parts-orders-body">
      <tabs ref="supplier-parts-orders-table"
            :options="{useUrlFragment:true}"
            :custom-counts="computedCustomCountForTab"
            cache-lifetime="0"
            @changed="tabChanged"
      >
        <tab name="All" id="all" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedAllOrders"
                :fields="fields"
                :filter="filter"
                :filter-function="filterTable"
                :current-page="gettersOrderAllCurrentPage"
                :per-page="gettersPerPageAll"
                @filtered="value => allPartsFiltered = value"
                @row-clicked="clickOrderRow"
                @row-contextmenu="openContextMenu"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateOrdered)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <template v-else-if="isToday(getDate(data.item))">
                  <span style="color: red">Today</span>
                </template>
                <template v-else-if="isTomorrow(getDate(data.item))">
                  <span style="color: #f0710f">Tomorrow</span>
                </template>
                <template v-else>
                  {{ getDate(data.item) | formatDate }}
                </template>
              </template>
              <template #cell(cost)="data">
                {{ data.value | formatMoney }}
              </template>
              <template #cell(location)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  data.item.status !== 'Shipped',
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.status === 'Shipped'}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true || data.item.partsStatuses.somePartsCancelled == true)"
                       class="bx bxs-flag-alt"
                       :class="{'red-icon': data.item.partsStatuses.somePartsCancelled, 'text-warning': !data.item.partsStatuses.somePartsCancelled}"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Orders'"
              :arrayOfSomeone="gettersAllOrders"
              :arrayOfSomeoneFiltered="filter ? allPartsFiltered : computedAllOrders"
              :currentPage="gettersOrderAllCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/order/supplierOrdersAllCurrentPage', value)"
              :perPage="gettersPerPageAll"
              @changedPerPage="value => $store.commit('supplier/order/supplierOrdersPerPageAll', value)"
          >
          </block-pagination>

        </tab>
        <tab name="New" id="open" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedOpenOrders"
                :fields="fields"
                :filter="filter"
                :filter-function="filterTable"
                :current-page="gettersOrderOpenCurrentPage"
                :per-page="gettersPerPageOpen"
                @row-contextmenu="openContextMenu"
                @filtered="value => openPartsFiltered = value"
                @row-clicked="clickOrderRow"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateOrdered)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <template v-else-if="isToday(getDate(data.item))">
                  <span style="color: red">Today</span>
                </template>
                <template v-else-if="isTomorrow(getDate(data.item))">
                  <span style="color: #f0710f">Tomorrow</span>
                </template>
                <template v-else>
                  {{ getDate(data.item) | formatDate }}
                </template>
              </template>
              <template #cell(cost)="data">
                {{ data.value | formatMoney }}
              </template>
              <template #cell(location)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  data.item.status !== 'Shipped',
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.status === 'Shipped'}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true || data.item.partsStatuses.somePartsCancelled == true)"
                       class="bx bxs-flag-alt"
                       :class="{'red-icon': data.item.partsStatuses.somePartsCancelled, 'text-warning': !data.item.partsStatuses.somePartsCancelled}"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Orders'"
              :arrayOfSomeone="gettersOpenOrders"
              :arrayOfSomeoneFiltered="filter ? openPartsFiltered : computedOpenOrders"
              :currentPage="gettersOrderOpenCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/order/supplierOrdersOpenCurrentPage', value)"
              :perPage="gettersPerPageOpen"
              @changedPerPage="value => $store.commit('supplier/order/supplierOrdersPerPageOpen', value)"
          >
          </block-pagination>

        </tab>
        <tab name="Processing" id="processing" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedProcessingOrders"
                :fields="fields"
                :filter="filter"
                :filter-function="filterTable"
                :current-page="gettersOrderProcessingCurrentPage"
                :per-page="gettersPerPageProcessing"
                @filtered="value => processingPartsFiltered = value"
                @row-contextmenu="openContextMenu"
                @row-clicked="clickOrderRow"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateOrdered)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <template v-else-if="isToday(getDate(data.item))">
                  <span style="color: red">Today</span>
                </template>
                <template v-else-if="isTomorrow(getDate(data.item))">
                  <span style="color: #f0710f">Tomorrow</span>
                </template>
                <template v-else>
                  {{ getDate(data.item) | formatDate }}
                </template>
              </template>
              <template #cell(cost)="data">
                {{ data.value | formatMoney }}
              </template>
              <template #cell(location)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  data.item.status !== 'Shipped',
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.status === 'Shipped'}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true || data.item.partsStatuses.somePartsCancelled == true)"
                       class="bx bxs-flag-alt"
                       :class="{'red-icon': data.item.partsStatuses.somePartsCancelled, 'text-warning': !data.item.partsStatuses.somePartsCancelled}"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Orders'"
              :arrayOfSomeone="gettersProcessingOrders"
              :arrayOfSomeoneFiltered="filter ? processingPartsFiltered : computedProcessingOrders"
              :currentPage="gettersOrderProcessingCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/order/supplierOrdersProcessingCurrentPage', value)"
              :perPage="gettersPerPageProcessing"
              @changedPerPage="value => $store.commit('supplier/order/supplierOrdersPerPageProcessing', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Parts Shipped" id="parts-shipped" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedShippedOrders"
                :fields="fields"
                :filter="filter"
                :filter-function="filterTable"
                :current-page="gettersOrderRouteCurrentPage"
                :per-page="gettersPerPageRoute"
                @filtered="value => shippedPartsFiltered = value"
                @row-contextmenu="openContextMenu"
                @row-clicked="clickOrderRow"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateOrdered)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <template v-else-if="isToday(getDate(data.item))">
                  <span style="color: red">Today</span>
                </template>
                <template v-else-if="isTomorrow(getDate(data.item))">
                  <span style="color: #f0710f">Tomorrow</span>
                </template>
                <template v-else>
                  {{ getDate(data.item) | formatDate }}
                </template>
              </template>
              <template #cell(cost)="data">
                {{ data.value | formatMoney }}
              </template>
              <template #cell(location)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  data.item.status !== 'Shipped',
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.status === 'Shipped'}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true || data.item.partsStatuses.somePartsCancelled == true)"
                       class="bx bxs-flag-alt"
                       :class="{'red-icon': data.item.partsStatuses.somePartsCancelled, 'text-warning': !data.item.partsStatuses.somePartsCancelled}"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Orders'"
              :arrayOfSomeone="gettersPartsShippedOrders"
              :arrayOfSomeoneFiltered="filter ? shippedPartsFiltered : computedShippedOrders"
              :currentPage="gettersOrderRouteCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/order/supplierOrdersRouteCurrentPage', value)"
              :perPage="gettersPerPageRoute"
              @changedPerPage="value => $store.commit('supplier/order/supplierOrdersPerPageRoute', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Partial Delivery" id="partialDelivery" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedPartialDeliveryOrders"
                :fields="fields"
                :filter="filter"
                :filter-function="filterTable"
                :current-page="gettersOrderPartialDeliveryCurrentPage"
                :per-page="gettersPerPagePartialDelivery"
                @filtered="value => partialDeliveryPartsFiltered = value"
                @row-contextmenu="openContextMenu"
                @row-clicked="clickOrderRow"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateOrdered)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <template v-else-if="isToday(getDate(data.item))">
                  <span style="color: red">Today</span>
                </template>
                <template v-else-if="isTomorrow(getDate(data.item))">
                  <span style="color: #f0710f">Tomorrow</span>
                </template>
                <template v-else>
                  {{ getDate(data.item) | formatDate }}
                </template>
              </template>
              <template #cell(cost)="data">
                {{ data.value | formatMoney }}
              </template>
              <template #cell(location)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  data.item.status !== 'Shipped',
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.status === 'Shipped'}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true || data.item.partsStatuses.somePartsCancelled == true)"
                       class="bx bxs-flag-alt"
                       :class="{'red-icon': data.item.partsStatuses.somePartsCancelled, 'text-warning': !data.item.partsStatuses.somePartsCancelled}"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Orders'"
              :arrayOfSomeone="gettersPartialDeliveredOrders"
              :arrayOfSomeoneFiltered="filter ? partialDeliveryPartsFiltered : computedPartialDeliveryOrders"
              :currentPage="gettersOrderPartialDeliveryCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/order/supplierOrdersPartialDeliveryCurrentPage', value)"
              :perPage="gettersPerPagePartialDelivery"
              @changedPerPage="value => $store.commit('supplier/order/supplierOrdersPerPagePartialDelivery', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Parts Delivered" id="parts-delivered" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedDeliveredOrders"
                :fields="fields"
                :filter="filter"
                :filter-function="filterTable"
                :current-page="gettersOrderDeliveredCurrentPage"
                :per-page="gettersPerPageDelivered"
                @row-contextmenu="openContextMenu"
                @filtered="value => deliveredPartsFiltered = value"
                @row-clicked="clickOrderRow"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateOrdered)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <template v-else-if="isToday(getDate(data.item))">
                  <span style="color: red">Today</span>
                </template>
                <template v-else-if="isTomorrow(getDate(data.item))">
                  <span style="color: #f0710f">Tomorrow</span>
                </template>
                <template v-else>
                  {{ getDate(data.item) | formatDate }}
                </template>
              </template>
              <template #cell(cost)="data">
                {{ data.value | formatMoney }}
              </template>
              <template #cell(location)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  data.item.status !== 'Shipped',
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.status === 'Shipped'}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true || data.item.partsStatuses.somePartsCancelled == true)"
                       class="bx bxs-flag-alt"
                       :class="{'red-icon': data.item.partsStatuses.somePartsCancelled, 'text-warning': !data.item.partsStatuses.somePartsCancelled}"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Orders'"
              :arrayOfSomeone="gettersPartsDeliveredOrders"
              :arrayOfSomeoneFiltered="filter ? deliveredPartsFiltered : computedDeliveredOrders"
              :currentPage="gettersOrderDeliveredCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/order/supplierOrdersDeliveredCurrentPage', value)"
              :perPage="gettersPerPageDelivered"
              @changedPerPage="value => $store.commit('supplier/order/supplierOrdersPerPageDelivered', value)"
          >
          </block-pagination>
        </tab>
        <tab name="Completed" id="completed" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="computedCompletedOrders"
                :fields="fields"
                :filter="filter"
                :filter-function="filterTable"
                :current-page="gettersOrderCompletedCurrentPage"
                :per-page="gettersPerPageCompleted"
                @filtered="value => completedPartsFiltered = value"
                @row-contextmenu="openContextMenu"
                @row-clicked="clickOrderRow"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{ data.label }}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{ data.label }}<i></i></div>
              </template>
              <template #cell(dateOrdered)="data">
                {{ data.value | formatDate }}
              </template>
              <template #cell(dateDue)="data">
                <template v-if="!getDate(data.item)">Next available run</template>
                <template v-else-if="isToday(getDate(data.item))">
                  <span style="color: red">Today</span>
                </template>
                <template v-else-if="isTomorrow(getDate(data.item))">
                  <span style="color: #f0710f">Tomorrow</span>
                </template>
                <template v-else>
                  {{ getDate(data.item) | formatDate }}
                </template>
              </template>
              <template #cell(cost)="data">
                {{ data.value | formatMoney }}
              </template>
              <template #cell(location)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(status)="data">
                <div class="d-flex flex-row justify-content-center font-20">
                  <div class="notf-icon">
                    <i class="bx bx-package"
                       :class="{
                         'text-success': data.item.status !== 'Open' && data.item.status !== 'Cancelled' && data.item.status !== 'Denied',
                         'red-icon': data.item.status === 'Denied' || data.item.status === 'Cancelled'}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: getTitleForPackageIcon(data.item),
                        disabled: data.item.status === 'Open',
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i
                        class='bx bxs-truck'
                        v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: 'Order Shipped',
                          disabled:  data.item.status !== 'Shipped',
                          placement:'leftbottom'
                       }"
                        :class="{'bxs-truck-active': data.item.status === 'Shipped'}"
                    ></i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-buildings"
                       :class="{'after-buildins bx-buildings-active': data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)}"
                       v-b-tooltip.hover="{
                        customClass: 'ps-tooltip',
                        title: 'Parts Received',
                        disabled:  !(data.item.partsStatuses && (data.item.partsStatuses.allPartsReceived || data.item.partsStatuses.somePartsReceived)),
                        placement:'leftbottom'
                       }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i class="bx bx-error-circle red-icon text-secondary"
                       v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsDamaged || data.item.partsStatuses.somePartsDamagedExWarehouse)"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForReturnPart(data.item),
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                    <i v-else
                       class="bx bx-error-circle"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                  <div class="notf-icon">
                    <i v-if="data.item.partsStatuses && (data.item.partsStatuses.somePartsIncorrect == true || data.item.partsStatuses.somePartsIncorrectOrdered == true || data.item.partsStatuses.somePartsNoLongerNeeded == true || data.item.partsStatuses.somePartsChangeInRepairMethod == true || data.item.partsStatuses.somePartsCancelled == true)"
                       class="bx bxs-flag-alt"
                       :class="{'red-icon': data.item.partsStatuses.somePartsCancelled, 'text-warning': !data.item.partsStatuses.somePartsCancelled}"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          title: getTooltipForIncorrectPart(data.item),
                          placement:'leftbottom'
                    }"
                    >
                    </i>
                    <i v-else
                       class="bx bxs-flag-alt"
                       v-b-tooltip.hover="{
                          customClass: 'ps-tooltip',
                          disabled: true,
                          title: '',
                          placement:'leftbottom'
                        }"
                    >
                    </i>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <block-pagination
              :role="'Orders'"
              :arrayOfSomeone="gettersCompletedOrders"
              :arrayOfSomeoneFiltered="filter ? completedPartsFiltered : computedCompletedOrders"
              :currentPage="gettersOrderCompletedCurrentPage"
              @changedCurrentPage="value => $store.commit('supplier/order/supplierOrdersCompletedCurrentPage', value)"
              :perPage="gettersPerPageCompleted"
              @changedPerPage="value => $store.commit('supplier/order/supplierOrdersPerPageCompleted', value)"
          >
          </block-pagination>
        </tab>
        <template slot="nav-item-right">
          <b-dropdown v-if="!isMobile" text="Options" class="dropdown-options" style="margin-bottom: 7px;">
            <template v-slot:button-content>
              Export
              <span class="dropdown-arrow"><i class='bx bx-down-arrow'></i></span>
            </template>
            <b-dropdown-item @click="exportReport('csv')">.CSV</b-dropdown-item>
            <b-dropdown-item @click="exportReport('xlsx')">.XLS</b-dropdown-item>
          </b-dropdown>
          <div v-else class="parts-orders-tabs-line"></div>
          <div class="tabs-nav-right-search">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filters="computedSearchFilters"
                :filterValue="filter"
                :isMyltipleOptions="true"
                :isMyltiple="true"
                :optionsLength="5"
                :placeholder="'Filter By'"
                :isShowSearIcon="false"
                :limit-length="50"
                :tokenCopy="computedToken"
                :tokensCopy="getterTokens"
                @updateToken="value => $store.commit('supplier/order/setToken', value)"
                @updateTokens="value => $store.commit('supplier/order/setTokens', value)"
                @change="onSearchFilterChange"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
            />
          </div>
        </template>
      </tabs>
    </div>

    <vue-context ref="menuOrders"
                 style="width: 155px"
                 :lazy="true"
                 class="supp-rfqs-context"
                 @close="contextClose"
                 @open="contextOpen"
                 :closeOnClick="false"
                 :closeOnScroll="true">
      <template slot-scope="item">
        <b-button-toolbar key-nav>
          <b-button-group
              vertical style="width: 155px"
              class="parts-order-view__context-menu"
          >
            <b-button
                v-if="item && item.data && item.data.row && item.data.row.status == 'Open'"
                @click="markAsProcessing(item.data.row)"
                class="text-left font-12 font-normal"
                variant="outline-primary"
                block
            >
              Mark as Processing
            </b-button>
            <b-button
                class="text-left font-12 font-normal"
                v-if="item && item.data && item.data.row && (item.data.row.status == 'Open' || item.data.row.status == 'Processing')"
                @click="denyOrderShowModal(item.data.row)"
                variant="outline-primary"
                block
            >
              Deny Order
            </b-button>
            <b-button
                v-if="item && item.data && item.data.row && (item.data.row.status == 'Open' || item.data.row.status == 'Processing')"
                @click="cancelOrderShowModal(item.data.row)"
                class="text-left font-12 font-normal"
                variant="outline-primary"
                block>
              Cancel Order
            </b-button>
            <b-button
                v-if="item && item.data && item.data.row && item.data.row.status === 'Processing'"
                @click="markOrderShipped(item.data.row.id, item.data.row.number)"
                class="text-left font-12 font-normal"
                variant="outline-primary"
                block>
              Mark Parts Shipped
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </template>
    </vue-context>

    <b-modal ref="deny-order-modal" centered size="deny-order-modal">
      <template #modal-header="{ close }">
        <h5 class="mb-0" v-if="selectedOrder">Deny Order {{selectedOrder.number}}</h5>
        <b-button class="button-close-modal-header" @click="close()">×</b-button>
      </template>
      <div class="modal-invite-customer form-container mt-3">
        <p class="pl-3 pr-3" v-if="selectedOrder">You about to Deny Order <b>{{selectedOrder.number}}</b> for <b>{{selectedOrder.customerName}}</b></p>
        <p class="pl-3 pr-3">Provide a reason below to notify them of this decision.</p>
        <b-textarea v-model="denyReason" class="deny-order-modal-textarea">
        </b-textarea>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no" @click="cancel()">Cancel</button>
          <button :disabled="!denyReason.length" class="input-btn modal-undo-part-line-change-btn-yes" @click="denyOrder">Confirm
          </button>
        </div>
      </template>
    </b-modal>

    <b-modal ref="cancel-order-modal" centered size="deny-order-modal">
      <template #modal-header="{ close }">
        <h5 class="mb-0" v-if="selectedOrder">Cancel Order {{selectedOrder.number}}</h5>
        <b-button class="button-close-modal-header" @click="close()">×</b-button>
      </template>
      <div class="modal-invite-customer form-container mt-3">
        <p class="pl-3 pr-3" v-if="selectedOrder">You about to Cancel Order <b>{{selectedOrder.number}}</b> for <b>{{selectedOrder.customerName}}</b></p>
        <p class="pl-3 pr-3">Provide a reason below to notify them of this decision.</p>
        <b-textarea v-model="cancelReason" class="deny-order-modal-textarea">
        </b-textarea>
      </div>
      <template v-slot:modal-footer="{ cancel, yes }">
        <div class="d-flex w-100 justify-content-end mb-3">
          <button class="input-btn modal-undo-part-line-change-btn-no" @click="cancel()">Cancel</button>
          <button :disabled="!cancelReason.length" class="input-btn modal-undo-part-line-change-btn-yes" @click="cancelOrder">Confirm
          </button>
        </div>
      </template>
    </b-modal>

  </div>
</template>
<script>
/*eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import notificationOnTable from '@/components/notification-on-table.vue'
import _ from 'lodash'
import BlockPagination from '../../../components/utility/block-pagination'
import SearchFilter from '../../../components/utility/search-filter/search-filter'
import * as dayjs from 'dayjs'
import { isMobile } from 'mobile-device-detect'

import {VueContext} from 'vue-context'
import Axios from "axios";
import WindowNotification from "@/components/utility/window-notification.vue";

var isToday = require('dayjs/plugin/isToday')
var isTomorrow = require('dayjs/plugin/isTomorrow')
dayjs.extend(isToday)
dayjs.extend(isTomorrow)

var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

export default {
  name: 'SuppliersPartsOrders',
  data: function () {
    return {
      currentTab: '',
      currentHash: '',
      // filter: '',
      searchFilter: '',
      searchFilter2: '',
      contextOpenEvent: null,
      denyReason: '',
      cancelReason: '',
      selectedOrder: null,
      allPartsFiltered: [],
      openPartsFiltered: [],
      processingPartsFiltered: [],
      shippedPartsFiltered: [],
      partialDeliveryPartsFiltered: [],
      deliveredPartsFiltered: [],
      completedPartsFiltered: [],
      statuses: [
        {
          value: 'Open',
          label: 'Open'
        },
        {
          value: 'Processing',
          label: 'Processing'
        },
        {
          value: 'Shipped',
          label: 'Parts Shipped'
        },
        {
          value: 'Delivered',
          label: 'Parts Delivered'
        },
        {
          value: 'Completed',
          label: 'Order Completed'
        }
      ],
      fields: [
        { label: 'Repairer Order Nbr', key: 'number', sortable: true, tdClass: 'clickable number', thClass: '' },
        { label: 'Invoice Nbr', key: 'invoiceNumber', sortable: true, tdClass: 'clickable number', thClass: '' },
        { label: 'Parts Type', key: 'rfqToSupplierType', sortable: true, tdClass: 'clickable', thClass: '' },
        {
          label: 'Customer Name',
          key: 'customerName',
          sortable: true,
          tdClass: 'clickable customer-name number',
          thClass: ''
        },
        { label: 'Make Model', key: 'makeModel', sortable: true, tdClass: 'clickable number', thClass: '' },
        { label: 'VIN', key: 'vin', sortable: true, tdClass: 'clickable', thClass: '' },
        { label: 'Order Total', key: 'cost', sortable: true, tdClass: 'clickable text-right pr-5 number', thClass: '' },
        { label: 'Date Ordered', key: 'dateOrdered', sortable: true, tdClass: 'clickable ', thClass: '' },
        { label: 'Parts Delivery Date', key: 'dateDue', sortable: true, tdClass: 'clickable number', thClass: '' },
        { label: 'Delivery Location', key: 'location', sortable: true, tdClass: 'clickable ', thClass: '' },
        {
          label: 'Parts Received %',
          key: 'partsFill',
          sortable: true,
          tdClass: 'clickable text-right pr-5 number',
          thClass: ''
        },
        { label: 'Status', key: 'status', sortable: true, tdClass: 'clickable ', thClass: '' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      gettersAllOrders: 'supplier/order/getAllOrders',
      gettersOpenOrders: 'supplier/order/getOpenOrders',
      gettersProcessingOrders: 'supplier/order/getProcessingOrders',
      gettersPartsShippedOrders: 'supplier/order/getPartsShippedOrders',
      gettersPartialDeliveredOrders: 'supplier/order/getPartialDeliveredOrders',
      gettersPartsDeliveredOrders: 'supplier/order/getPartsDeliveredOrders',
      gettersCompletedOrders: 'supplier/order/getCompletedOrders',
      gettersFilter: 'supplier/order/getFilter',
      gettersPerPageAll: 'supplier/order/perPageAll',
      gettersPerPageOpen: 'supplier/order/perPageOpen',
      gettersPerPageProcessing: 'supplier/order/perPageProcessing',
      gettersPerPageRoute: 'supplier/order/perPageRoute',
      gettersPerPagePartialDelivery: 'supplier/order/perPagePartialDelivery',
      gettersPerPageDelivered: 'supplier/order/perPageDelivered',
      gettersPerPageCompleted: 'supplier/order/perPageCompleted',
      gettersOrderAllCurrentPage: 'supplier/order/orderAllCurrentPage',
      gettersOrderOpenCurrentPage: 'supplier/order/orderOpenCurrentPage',
      gettersOrderProcessingCurrentPage: 'supplier/order/orderProcessingCurrentPage',
      gettersOrderRouteCurrentPage: 'supplier/order/orderRouteCurrentPage',
      gettersOrderDeliveredCurrentPage: 'supplier/order/orderDeliveredCurrentPage',
      gettersOrderPartialDeliveryCurrentPage: 'supplier/order/orderPartialDeliveryCurrentPage',
      gettersOrderCompletedCurrentPage: 'supplier/order/orderCompletedCurrentPage',
      getterSearchFilterObj: 'supplier/order/getSearchFilterObj',
      getterToken: 'supplier/order/getToken',
      getterTokens: 'supplier/order/getTokens',
    }),
    additionalMenu() {
      if (_.isEmpty(this.searchFilter2)) {
        return []
      }
      let data = _.filter(this.gettersAllOrders, (r) => {
        if (_.includes(this.searchFilter2, ' -')) {
          return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter2.split(' -')?.[0]))) &&  _.startsWith(_.trim(_.toLower(r.customerName)), _.trim(_.toLower(this.searchFilter2.split(' -')?.[1])))
        }
        return _.startsWith(_.trim(_.toLower(r.repairerQuoteNumber)), _.trim(_.toLower(this.searchFilter2)))
      })

      data = _.orderBy(data, [(itm) => Number(itm.id)], ['asc'])
      data = _.uniqBy(data, itm => {return Number(itm.repairerMainRfqId)})

      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.repairerQuoteNumber
        if (itm.customerName) {
          label +=  ' - ' + itm.customerName
        }

        result.push({
          value: itm.repairerMainRfqId,
          number: itm.repairerQuoteNumber,
          label: label,
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      return result
    },
    testDayjs () {
      return dayjs('01/03/2023', 'DD/MM/YYYY')
    },
    filteredItems () {
      let data = []
      if (this.filter) {
        data = this.partsFiltered
      } else if (this.currentTab === 'completed') {
        data = this.computedCompletedOrders
      } else if (this.currentTab === 'partsDelivered') {
        data = this.computedDeliveredOrders
      } else if (this.currentTab === 'partialDelivery') {
        data = this.computedPartialDeliveryOrders;
      } else if (this.currentTab === 'partsShipped') {
        data = this.computedShippedOrders
      } else if (this.currentTab === 'processing') {
        data = this.computedProcessingOrders
      } else if (this.currentTab === 'open') {
        data = this.computedOpenOrders
      } else {
        data = this.computedAllOrders
      }
      let result = ''
      _.forEach(data, (itm, index) => {
        if (index === data.length - 1) {
          result += itm.id
        } else {
          result += itm.id + ','
        }
      })
      return result
    },
    isDateRangeFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].dateRange) {
          return true
        }
      }
      return false
    },
    isPartNumberFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].partNumber) {
          return true
        }
      }
      return false
    },
    isInvoiceNumberFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].invoiceNumber) {
          return true
        }
      }
      return false
    },
    isPartStockStatusFilterActive(){
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].partStockStatus) {
          return true
        }
      }
      return false
    },
    isPartReceivedStatusFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].partRcvdStatus) {
          return true
        }
      }
      return false
    },
    isCustomerNameFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].customer) {
          return true
        }
      }
      return false
    },
    isBackOrderCustomerNameFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].backOrderCustomer) {
          return true
        }
      }
      return false
    },
    isNumberFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].number) {
          return true
        }
      }
      return false
    },
    computedPartNumberFilter () {
      let filter = {
        partNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].partNumber) {
          filter = obj[key]
          break
        }
      }
      let formattedFilter = filter.partNumber.toLowerCase().split(';').filter(Boolean)
      return formattedFilter
    },
    computedInvoiceNumberFilter () {
      let filter = {
        invoiceNumber: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].invoiceNumber) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.invoiceNumber, ';')
      return filter
    },
    computedDateRangeFilter () {
      let filter = {
        start: '',
        end: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].dateRange && obj[key].dateRange.start && obj[key].dateRange.end) {
          filter.start = dayjs(obj[key].dateRange.start, 'DD/MM/YYYY').unix()
          filter.end = dayjs(obj[key].dateRange.end, 'DD/MM/YYYY').unix()
          break
        }
      }
      return filter
    },
    computedCustomerNameFilter () {
      let filter = {
        customer: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].customer) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.customer, ';')
      return filter
    },
    computedBackOrderCustomerNameFilter () {
      let filter = {
        backOrderCustomer: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].backOrderCustomer) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.backOrderCustomer, ';')
      return filter
    },
    computedNumberFilter () {
      let filter = {
        number: '',
      }
      let obj = Object.assign({}, this.searchFilterObj)
      for (let key in obj) {
        if (obj[key].number) {
          filter = obj[key]
          break
        }
      }
      filter = _.split(filter.number, ';')
      return filter
    },
    computedCustomCountForTab () {
      let counts = {
        all: 0,
        open: 0,
        processing: 0,
        'parts-shipped': 0,
        'parts-delivered': 0,
        completed: 0,
        partialDelivery: 10,
      }
      counts.all = this.gettersAllOrders.length
      counts.open = this.gettersOpenOrders.length
      counts.processing = this.gettersProcessingOrders.length
      counts['parts-shipped'] = this.gettersPartsShippedOrders.length
      counts['parts-delivered'] = this.gettersPartsDeliveredOrders.length
      counts.partialDelivery = this.gettersPartialDeliveredOrders.length
      counts.completed = this.gettersCompletedOrders.length
      return counts
    },
    filter: {
      get () {
        return this.gettersFilter
      },
      set (value) {
        this.setFilter(value)
      }
    },
    computedToken () {
      return this.getterToken
    },

    computedSearchFilters () {
      let partNumbers = {}
      let customers = {}
      let backOrderCustomers = {}
      let repairerOrderNumbers = {}
      let invoiceNbrs = {}

      this.gettersAllOrders.forEach(order => {
        if (order.partsNumbers) {
          let numbers = order.partsNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = { label: n.toLowerCase().trim() }
          })
        }

      })

      this.gettersAllOrders.forEach(order => {
        if (order.customerName) {
          customers[order.customerName.trim().toLowerCase()] = { label: order.customerName.trim() }
        }
      })

      this.gettersAllOrders.forEach(order => {
        if (order.customerName && order.isBackOrderAcceptances) {
          backOrderCustomers[order.customerName.trim().toLowerCase()] = { label: order.customerName.trim() }
        }
      })

      this.gettersAllOrders.forEach(order => {
        if (order.number) {
          repairerOrderNumbers[order.number.trim().toLowerCase()] = { label: order.number.trim() }
        }
      })

      this.gettersAllOrders.forEach(order => {
        if (order.invoiceNumber) {
          invoiceNbrs[order.invoiceNumber.trim().toLowerCase()] = { label: order.invoiceNumber.trim() }
        }
      })

      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      customers = _.orderBy(Object.values(customers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      backOrderCustomers = _.orderBy(Object.values(backOrderCustomers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      backOrderCustomers.unshift({label: 'All Customers'})
      repairerOrderNumbers = _.orderBy(Object.values(repairerOrderNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      invoiceNbrs = _.orderBy(Object.values(invoiceNbrs), [(itm) => itm.label.toLowerCase().trim()], ['asc'])

      // // Create date template
      const date = [
        {
          id: 'date',
          label: 'Date',
          resultObjectOnly: true,
          type: 'Date',
          iconClass: 'bx-calendar'
        }
      ]

      let partStockStatuses = [
        { label: 'None' },
        { label: 'In Stock' },
        { label: 'Low Stock' },
        { label: 'No Stock' },
        { label: 'On Back Order' },
        { label: 'No Longer Avail' },
      ]

      let partRcvdStatuses = [
        { label: 'Received' },
        { label: 'Incorrect Part Delivered' },
        { label: 'Incorrect Part Ordered' },
        { label: 'Damaged Part in Transport' },
        { label: 'Damaged Part EX Warehouse' },
        { label: 'No Longer Needed' },
        { label: 'Change in Repair Method' },
        { label: 'Cancelled Part' },
        { label: 'Not Received' },
        { label: 'Partial Delivery' },
        { label: 'Order Completed' },
      ]

      // Create searchFilter filters
      return [
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'customer',
          label: 'Customer',
          iconClass: 'bx-user',
          options: [
            customers,
          ]
        },
        {
          id: 'number',
          label: 'Repairer Order Nbr',
          iconClass: 'bx-user',
          options: [
            repairerOrderNumbers
          ]
        },
        {
          id: 'invoiceNumber',
          label: 'Invoice Nbr',
          iconClass: 'bx-user',
          options: [
              invoiceNbrs
          ]
        },
        {
          id: 'backOrderCustomer',
          label: 'Back Order Acceptances',
          iconClass: 'bx-user',
          options: [
            backOrderCustomers
          ]
        },
        {
          id: 'partStockStatus',
          label: 'Part Stock Status',
          iconClass: 'bx-user',
          options: [
            partStockStatuses,
          ]
        },
        {
          id: 'partRcvdStatus',
          label: 'Part Rcvd Status',
          iconClass: 'bx-user',
          options: [
            partRcvdStatuses,
          ]
        },
        {
          id: 'dateRange',
          label: 'Date Range',
          iconClass: 'bx-store',
          options: [
            []
          ]
        }
      ]
    },
    searchFilterObj () {
      return this.getterSearchFilterObj
    },
    computedAllOrders () {
      let orders = this.gettersAllOrders.slice()
      orders = this.filterOrdersForComputed(orders)
      return this.filterByDate(orders)
    },
    computedOpenOrders () {
      let orders = this.gettersOpenOrders.slice()
      orders = this.filterOrdersForComputed(orders)
      return this.filterByDate(orders)
    },
    computedProcessingOrders () {
      let orders = this.gettersProcessingOrders.slice()
      orders = this.filterOrdersForComputed(orders)
      return this.filterByDate(orders)
    },
    computedShippedOrders () {
      let orders = this.gettersPartsShippedOrders.slice()
      orders = this.filterOrdersForComputed(orders)
      return this.filterByDate(orders)
    },
    computedPartialDeliveryOrders () {
      let orders = this.gettersPartialDeliveredOrders.slice()
      orders = this.filterOrdersForComputed(orders)
      return this.filterByDate(orders)
    },
    computedDeliveredOrders () {
      let orders = this.gettersPartsDeliveredOrders.slice()
      orders = this.filterOrdersForComputed(orders)
      return this.filterByDate(orders)
    },
    computedCompletedOrders () {
      let orders = this.gettersCompletedOrders.slice()
      orders = this.filterOrdersForComputed(orders)
      return this.filterByDate(orders)
    },
    isMobile () {
      return isMobile
    },
  },
  methods: {
    ...mapActions({
      setFilter: 'supplier/order/setFilter'
    }),
    redirectToRfqView(itm) {
      this.setFilter('')
      this.$router.push({ name: 'RefView', params: { rfqId: itm.value, number: itm.number } })
    },
    onEnter() {
      setTimeout(() => {
        let item =  this.additionalMenu[0]

        if (item && !_.isEmpty(this.searchFilter2)) {
          this.setFilter('')
          this.$router.push({ name: 'RefView', params: { rfqId: item.value, number: item.number } })
        }
      }, 500)
    },
    getDate(item) {
      if (_.isEmpty(item.supplierDeliveryDate) && _.isEmpty(item.supplierOrderPartsDeliveryETA) && _.isEmpty(item.repairerDeliveryDate)) {
        return item.dateDue
      }
      return item.dateDue
      // let d1 = new Date(dayjs(item.supplierDeliveryDate).format('YYYY-MM-DD')).getTime()
      // let d2 = new Date(dayjs(item.supplierOrderPartsDeliveryETA).format('YYYY-MM-DD')).getTime()
      // let d3 = new Date(dayjs(item.repairerDeliveryDate).format('YYYY-MM-DD')).getTime()
      // return new Date(_.max([d1, d2, d3]))
    },
    denyOrder () {
      this.$refs['deny-order-modal'].hide();
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.selectedOrder.id, { 'denyOrder': true, reasonDeny: this.denyReason })
          .then(response => {
            if (response.data._status) {
              this.$toast.success('Order ' + this.selectedOrder.number + ' has been denied')
              this.denyReason = '';
              this.$store.commit('supplier/order/setOrderStatus', {id: this.selectedOrder.id, status: 'Denied'})
              this.selectedOrder = null;
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    markOrderShipped (id, number) {
      this.$refs.menuOrders.close()
      Axios.post('/ir/supplier/order/' + id + '/shipped', {partsAll: true})
          .then(response => {
            NProgress.done()
            if (response.data.status || response.data._status) {
              this.$toast.success('Order ' + number + ' has been marked as Shipped')
              this.$store.commit('supplier/order/setOrderStatus', {id: id, status: 'Shipped'})
            }
          })
          .catch(error => {
            console.log(error)
            this.$toast.error('An error occurred in submitting request')
          }).finally(() => {
            NProgress.done()
          })
    },
    cancelOrder () {
      this.$refs['cancel-order-modal'].hide();
      NProgress.start()
      Axios.post('/ir/supplier/order/' + this.selectedOrder.id, { 'cancelOrder': true, 'reasonCanceled': this.cancelReason })
          .then(response => {
            if (response.data._status) {
              this.$toast.success('Order ' + this.selectedOrder.number + ' has been cancelled')
              this.$store.commit('supplier/order/setOrderStatus', {id: this.selectedOrder.id, status: 'Cancelled'})
              this.selectedOrder = null;
              this.cancelReason = ''
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    denyOrderShowModal(item) {
      this.$refs.menuOrders.close()
      this.selectedOrder = item
      this.$refs['deny-order-modal'].show();
    },
    cancelOrderShowModal(item) {
      this.$refs.menuOrders.close()
      this.selectedOrder = item
      this.$refs['cancel-order-modal'].show();
    },
    markAsProcessing(item) {
      this.$refs.menuOrders.close()
      NProgress.start()
      Axios.post('/ir/supplier/order/' + item.id + '/processing', {
        partsAll: true
      })
          .then(response => {
            if (response.data.status) {
              this.$toast.success('Order marked as Processing')
              this.$store.commit('supplier/order/setOrderStatus', {id: item.id, status: 'Processing'})
            }
          }).catch(error => {
            console.log(error)

          }).finally(() => {
            NProgress.done()
          })
    },
    contextOpen: function (event) {
      this.contextOpenEvent = event
    },
    contextClose: function () {
      let event = this.contextOpenEvent
      if (event) {
        let row = event.target.parentElement
        let rows = row.parentElement.rows
        while (!rows) {
          row = row.parentElement
          rows = row.parentElement.rows
        }
        if (rows) {
          for (let i = 0; i < rows.length; i++) {
            let elem = rows[i]
            elem.style.backgroundColor = ''
          }
        }
      }
    },
    openContextMenu(row, index, event) {
      event.preventDefault()

      if (row.status !== 'Open' && row.status !== 'Processing') {
        return
      }
      let tableRow = event.target.parentElement
      let rows = tableRow.parentElement.rows
      while (!rows) {
        tableRow = tableRow.parentElement
        rows = tableRow.parentElement.rows
      }
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          let elem = rows[i]
          elem.style.backgroundColor = ''
        }
      }
      tableRow.style.backgroundColor = 'rgb(233,248,249)'
      this.$refs.menuOrders.open(event, {row: row, index: index})
    },
    isToday(v) {
      return dayjs(v).isToday()
    },
    isTomorrow(v) {
      return dayjs(v).isTomorrow()
    },
    filterByDate(currentOrders) {
      return _.orderBy(currentOrders, ['dateOrdered'], ['desc'])

      //removed by https://gitlab.naevette.com.au/partssearch/partssearch-app/-/issues/605#note_47499
      // currentOrders = _.orderBy(currentOrders, ['dateDue'], ['desc'])

      // let nextAvailRunItems = _.filter(currentOrders, (order) => {
      //   return order.dateDue == null
      // })
      // let tomorrowItems = _.filter(currentOrders, (order) => {
      //   return this.isTomorrow(order.dateDue)
      // })
      // let todayItems = _.filter(currentOrders, (order) => {
      //   return this.isToday(order.dateDue)
      // })
      // let otherItems = _.filter(currentOrders, (order) => {
      //   return !this.isToday(order.dateDue) && !this.isToday(order.dateDue) && order.dateDue !== null
      // })
      //
      // return _.uniqBy(_.concat(todayItems, tomorrowItems, otherItems, nextAvailRunItems),'id')
    },
    getTitleForPackageIcon(item) {
      if (item.status === 'Processing' && this.isOrderProcessing(item)) {
        return 'Order in Processing'
      }
      if (item.status === 'Denied') {
        return 'Order Denied'
      }
      if (item.status === 'Cancelled') {
        return 'Order Cancelled'
      }
      return 'Order Processed'
    },
    isOrderProcessing (item) {
      return item &&
          item.partsStatuses &&
          !item.partsStatuses.allPartsReceived &&
          !item.partsStatuses.somePartsReceived &&
          !item.partsStatuses.somePartsDamaged &&
          !item.partsStatuses.somePartsDamagedExWarehouse &&
          !item.partsStatuses.somePartsIncorrect &&
          !item.partsStatuses.somePartsIncorrectOrdered &&
          !item.partsStatuses.somePartsNoLongerNeeded &&
          !item.partsStatuses.somePartsChangeInRepairMethod
    },
    getTooltipForIncorrectPart(part) {
      let result = ''
      let inc = part?.partsStatuses?.somePartsIncorrect == true;
      let incOrd = part?.partsStatuses?.somePartsIncorrectOrdered == true;
      let noLon = part?.partsStatuses?.somePartsNoLongerNeeded == true;
      let charIn = part?.partsStatuses?.somePartsChangeInRepairMethod == true;
      let canc = part?.partsStatuses?.somePartsCancelled == true;

      if (canc) {
        result += 'An item or Items have been cancelled in this order.'
      }

      if (inc) {
        if (canc) {
          result += ', '
        }
        result += 'Incorrect Part Delivered'
      }
      if (incOrd) {
        if (canc || inc) {
          result += ', '
        }
        result += 'Incorrect Part Ordered'
      }
      if (noLon) {
        if (inc || canc || incOrd) {
          result += ', '
        }
        result += 'No Longer Needed'
      }
      if (charIn) {
        if (inc || canc || incOrd || noLon) {
          result += ', '
        }
        result += 'Change in Repair Method'
      }

      return result
    },
    getTooltipForReturnPart(part) {
      let result = ''
      if (part?.partsStatuses?.somePartsDamagedExWarehouse == true && part?.partsStatuses?.somePartsDamaged == true) {
        result = 'Damaged Part in Transport and EX Warehouse'
      } else if (part?.partsStatuses?.somePartsDamagedExWarehouse == true) {
        result = 'Damaged Part EX Warehouse'
      } else if (part?.partsStatuses?.somePartsDamaged == true) {
        result = 'Damaged Part in Transport'
      }
      return result
    },
    filterOrdersForComputed (orders) {
      if (this.isPartNumberFilterActive) {
        let formattedFilter = this.computedPartNumberFilter
        orders = _.filter(orders, order => {
          let bool = false
          _.forEach(formattedFilter, (nbr) => {
            if (order.partsNumbers && _.includes(_.toLower(order.partsNumbers), _.trim(_.toLower(nbr)))) {
              bool = true
            }
          })
          return bool
        })
      }
      if (this.isDateRangeFilterActive) {
        orders = _.filter(orders, order => {
          let time = dayjs(order.dateOrdered).unix()
          if (order.dateOrdered && time > 0 && time >= this.computedDateRangeFilter.start && time <= this.computedDateRangeFilter.end) {
            // console.log('isDateRangeFilterActive.unix', dayjs.unix(time).format('DD/MM/YYYY'))
            // console.log('isDateRangeFilterActive.unix', dayjs.unix(this.computedDateRangeFilter.start).format('DD/MM/YYYY'))
            // console.log('isDateRangeFilterActive.unix', dayjs.unix(this.computedDateRangeFilter.end).format('DD/MM/YYYY'))
            return true
          }
          return false
        })
      }
      if (this.isCustomerNameFilterActive) {
        orders = _.filter(orders, order => {
          return order.customerName && _.some(this.computedCustomerNameFilter, (itm) => itm == order.customerName)
        })
      }

      if (this.isBackOrderCustomerNameFilterActive) {
        orders = _.filter(orders, order => {
          return order.customerName && _.some(this.computedBackOrderCustomerNameFilter, (itm) => ((itm == 'All Customers' || itm == order.customerName) && order.isBackOrderAcceptances))
        })
      }

      if (this.isNumberFilterActive) {
        orders = _.filter(orders, order => {
          return order.number && _.some(this.computedNumberFilter, (itm) => itm == order.number)
        })
      }

      if (this.isInvoiceNumberFilterActive) {
        orders = _.filter(orders, order => {
          return order.invoiceNumber && _.some(this.computedInvoiceNumberFilter, (itm) => itm == order.invoiceNumber)
        })
      }

      if (this.isPartReceivedStatusFilterActive) {
        let filter = []
        let isReceivedFilterActive = false
        let isNotReceivedFilterActive = false
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].partRcvdStatus) {
            filter = obj[key]
            let statuses = obj[key].partRcvdStatus.split(';').filter(Boolean)
            _.forEach(statuses, (st) => {
              if (st === 'Received') {
                isReceivedFilterActive = true
              }
              if (st === 'Not Received') {
                isNotReceivedFilterActive = true
              }
            })
            break
          }
        }
        orders = _.filter(orders, order => {
          let bool = false
          if (isReceivedFilterActive) {
            if (order.partsStatuses && (order.partsStatuses.somePartsReceived || order.partsStatuses.allPartsReceived)) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Order Completed')) {
            if (order.status === 'Completed') {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Incorrect Part Delivered')) {
            if (order.partsStatuses && order.partsStatuses.somePartsIncorrect) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Partial Delivery')) {
            if (order.partsStatuses && order.partsStatuses.somePartsPartialDelivery) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Incorrect Part Ordered')) {
            if (order.partsStatuses && order.partsStatuses.somePartsIncorrectOrdered) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('No Longer Needed')) {
            if (order.partsStatuses && order.partsStatuses.somePartsNoLongerNeeded) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Change in Repair Method')) {
            if (order.partsStatuses && order.partsStatuses.somePartsChangeInRepairMethod) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Damaged Part in Transport')) {
            if (order.partsStatuses && order.partsStatuses.somePartsDamaged) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Damaged Part EX Warehouse')) {
            if (order.partsStatuses && order.partsStatuses.somePartsDamagedExWarehouse) {
              bool = true
            }
          }
          if (filter.partRcvdStatus.includes('Cancelled Part')) {
            if (order.partsStatuses && order.partsStatuses.somePartsCancelled) {
              bool = true
            }
          }
          if (isNotReceivedFilterActive) {
            if (order.partsStatuses && order.partsStatuses.somePartsReceived === false) {
              bool = true
            }
          }
          return bool
        })
      }

      if (this.isPartStockStatusFilterActive) {
        let filter = ''
        let obj = Object.assign({}, this.searchFilterObj)
        for (let key in obj) {
          if (obj[key].partStockStatus) {
            filter = obj[key].partStockStatus
            break
          }
        }
        orders = _.filter(orders, order => {
          let bool = false
          if (filter.includes('None')) {
            if (order.partsStocks && order.partsStocks.someNone) {
              bool = true
            }
          }
          if (filter.includes('In Stock')) {
            if (order.partsStocks && order.partsStocks.someInStock) {
              bool = true
            }
          }
          if (filter.includes('Low Stock')) {
            if (order.partsStocks && order.partsStocks.someLowStock) {
              bool = true
            }
          }
          if (filter.includes('No Stock')) {
            if (order.partsStocks && order.partsStocks.someNoStock) {
              bool = true
            }
          }
          if (filter.includes('On Back Order')) {
            if (order.partsStocks && order.partsStocks.someOnBackOrder) {
              bool = true
            }
          }
          if (filter.includes('No Longer Avail')) {
            if (order.partsStocks && order.partsStocks.someNoLongerAvailable) {
              bool = true
            }
          }
          return bool
        })
      }
      return orders
    },
    tabChanged (selectedTab) {
      this.currentTab = selectedTab.tab.id
      this.currentHash = selectedTab.tab.hash
    },
    exportReport (type) {
      let token = localStorage.getItem('token')
      window.open(appConfig.baseAPIURL + `/ir/supplier/orders/export-table?ids=${this.filteredItems}&direction=desc&format=${type}&at=${token}`)
    },
    filterTable (row, filter) {
      let formattedFilter = filter.toLowerCase()
      let dateDue = row.dateDue ? dayjs(row.dateDue).format('DD/MM/YYYY || hh:mmA') : ''
      let dateOrdered = row.dateOrdered ? dayjs(row.dateOrdered).format('DD/MM/YYYY') : ''

      if (dateDue && _.includes(_.toLower(dateDue), formattedFilter)) {
        return true
      } else if (dateOrdered && _.includes(_.toLower(dateOrdered), formattedFilter)) {
        return true
      } else if (row.partsNumbers &&  _.includes(_.toLower(row.partsNumbers), formattedFilter)) {
        return true
      } else if (row.customerName && _.includes(_.toLower(row.customerName), formattedFilter)) {
        return true
      } else if (row.costStr && _.includes(_.toLower(row.costStr), formattedFilter)) {
        return true
      } else if (row.estimator && _.includes(_.toLower(row.estimator), formattedFilter)) {
        return true
      } else if (row.grossValue && _.includes(_.toLower(row.grossValue), formattedFilter)) {
        return true
      } else if (row.number && _.includes(_.toLower(row.number), formattedFilter)) {
        return true
      } else if (row.vin && _.includes(_.toLower(row.vin), formattedFilter)) {
        return true
      }

    },
    onSearchFilterChange (filters) {
      this.$store.commit('supplier/order/setSearchFilterObj', filters)
      console.log(filters, 'filters')
      if (filters && filters[0] && filters[0].search) {
        this.setFilter(filters[0].search)
        // this.filter = filters.search;
      } else {
        this.setFilter('')
        // this.filter = '';
      }
      // console.log('onSearchFilterChange', filters);
    },
    clickOrderRow: function (item, index, event) {
      this.$router.push({ name: 'SupplierPartsOrderView', params: { orderId: item.id, hash: this.currentHash } })
    },
    currentStatus (status) {
      let result = _.find(this.statuses, item => {
        return item.value === status
      })
      return (!_.isEmpty(result)) ? result.label : ''
    }

  },
  components: {
    WindowNotification,
    notificationOnTable,
    BlockPagination,
    VueContext,
    SearchFilter
  }
}
</script>

<style scoped>
.tab-content-table{
  height: calc(100vh - 60px - 75px - 110px);
  overflow: hidden;
}

.button-close-modal-header {
  background-color: var(--supplier-color);
  color: #000;
  opacity: .4;
  padding: 0;
  border: none;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  font-family: 'Inter', sans-serif;
}

.modal-undo-part-line-change-btn-yes:disabled {
  background-color: #bdbdbd !important;
}

.modal-undo-part-line-change-btn-yes:disabled:hover {
  color: #ccc !important;
  cursor: not-allowed;
}

.button-close-modal-header:hover {
  background-color: var(--supplier-color);
}

.deny-order-modal-textarea {
  width: 92%;
  margin-top: 15px;
  margin-left: 15px;
  height: 80px;
}

.red-icon {
  color: red !important;
}

.supplier-parts-orders-page .status .status-ordered {
  color: #2DCA73;
}

.supplier-parts-orders-body .notf-icon {
  padding: 0 5px;
  color: #c6c7ce;
}

.supplier-parts-orders-body .bx-buildings {
  position: relative;
}

.bx-buildings-active {
  color: #1B1E38;
}
.supplier-parts-orders-body .bxs-truck-active{
  color: #43B968 !important;
}

.supplier-parts-orders-body .after-buildins:after {
  content: '';
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDJDNi40ODYgMiAyIDYuNDg2IDIgMTJDMiAxNy41MTQgNi40ODYgMjIgMTIgMjJDMTcuNTE0IDIyIDIyIDE3LjUxNCAyMiAxMkMyMiA2LjQ4NiAxNy41MTQgMiAxMiAyWk0xMC4wMDEgMTYuNDEzTDYuMjg4IDEyLjcwOEw3LjcgMTEuMjkyTDkuOTk5IDEzLjU4N0wxNS4yOTMgOC4yOTNMMTYuNzA3IDkuNzA3TDEwLjAwMSAxNi40MTNaIiBmaWxsPSIjMkRDQTczIi8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-size: contain;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  top: 0;
  right: 0;
}
</style>

<style>
.supplier-parts-orders-page .tabs-component .float-sm-right {
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.supplier-parts-orders-page .custom-dropdown {
  min-width: 14rem !important;
}
</style>
